// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "w_rR d_gS d_cw d_dv";
export var quoteParagraphCenter = "w_gT d_gT d_cw d_dw";
export var quoteParagraphRight = "w_gV d_gV d_cw d_dx";
export var quoteRowLeft = "w_rS d_bG";
export var quoteRowCenter = "w_rT d_bD";
export var quoteRowRight = "w_rV d_bH";
export var quoteWrapper = "w_gQ d_gQ d_w d_cD d_bz d_bP d_bD";
export var quoteContentWrapper = "w_gR d_gR";
export var quoteExceptionSmall = "w_rW F_rW";
export var quoteExceptionNormal = "w_rX F_rX";
export var quoteExceptionLarge = "w_rY F_rY";
export var quoteAuthorExceptionSmall = "w_rZ F_rZ";
export var quoteAuthorExceptionNormal = "w_r0 F_r0";
export var quoteAuthorExceptionLarge = "w_r1 F_r1";