// extracted by mini-css-extract-plugin
export var alignLeft = "H_qj d_fp d_bG d_dv";
export var alignCenter = "H_bP d_fq d_bD d_dw";
export var alignRight = "H_qk d_fr d_bH d_dx";
export var verticalDottedFirstComponent = "H_t4";
export var wrap = "H_t5 d_bJ d_cb";
export var topPadding = "H_t6 d_cX";
export var compContent = "H_t7 d_w";
export var last = "H_t8";
export var box = "H_t9 d_cr";
export var boxWrapper = "H_vb d_w d_bz";
export var boxWrapperFirst = "H_vc d_w d_bz";