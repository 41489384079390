// extracted by mini-css-extract-plugin
export var alignLeft = "n_qj d_fp d_bG d_dv";
export var alignCenter = "n_bP d_fq d_bD d_dw";
export var alignRight = "n_qk d_fr d_bH d_dx";
export var comparisonContainer = "n_ql d_dX";
export var comparisonContainerFull = "n_qm d_dV";
export var comparisonFloatyBox = "n_h1 d_h1 d_by d_Z d_cz";
export var comparisonSubtitle = "n_jb d_jb d_w d_c5";
export var comparisonRow = "n_h6 d_h6 d_cc d_bJ";
export var comparisonMainHeader = "n_h7 d_h7 d_w d_cw";
export var comparisonComponentText = "n_h3 d_h3 d_w";
export var comparisonBtnWrapper = "n_jj d_jj d_d1 d_w d_bz";
export var comparisonBtnWrapperSecond = "n_jk d_jk d_w";
export var comparisonImageContainer = "n_jg d_jg d_Z d_by d_w";
export var stretch = "n_qn";
export var limit = "n_qp";
export var exceptionWeight = "n_qq F_tw";