// extracted by mini-css-extract-plugin
export var alignDiscLeft = "s_qY d_fp d_bG d_dv";
export var alignLeft = "s_qj d_fp d_bG d_dv";
export var alignDiscCenter = "s_qZ d_fq d_bD d_dw";
export var alignCenter = "s_bP d_fq d_bD d_dw";
export var alignDiscRight = "s_q0 d_fr d_bH d_dx";
export var alignRight = "s_qk d_fr d_bH d_dx";
export var footerContainer = "s_q1 d_dW d_bW";
export var footerContainerFull = "s_q2 d_dT d_bW";
export var footerHeader = "s_kf d_kf";
export var footerTextWrapper = "s_q3 d_w";
export var footerDisclaimerWrapper = "s_km d_km d_ck";
export var badgeWrapper = "s_q4 d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "s_q5 d_bz d_bJ d_bN d_bL";
export var wide = "s_q6 d_cy";
export var right = "s_q7 d_bK";
export var line = "s_fk d_fl d_cv";
export var badgeDisclaimer = "s_q8 d_bC d_bP d_bJ";
export var badgeContainer = "s_q9 d_bz d_bH d_bP";
export var badge = "s_rb";
export var padding = "s_rc d_c7";
export var end = "s_rd d_bH";
export var linkWrapper = "s_rf d_dB";
export var link = "s_mC d_dB";
export var colWrapper = "s_rg d_cx";
export var consent = "s_f d_f d_bC d_bP";
export var disclaimer = "s_rh d_bz d_bJ";
export var media = "s_rj d_bx d_dy";
export var itemRight = "s_rk";
export var itemLeft = "s_rl";
export var itemCenter = "s_rm";
export var exceptionWeight = "s_qq F_tw";