// extracted by mini-css-extract-plugin
export var lbContainerOuter = "M_wb";
export var lbContainerInner = "M_wc";
export var movingForwards = "M_wd";
export var movingForwardsOther = "M_wf";
export var movingBackwards = "M_wg";
export var movingBackwardsOther = "M_wh";
export var lbImage = "M_wj";
export var lbOtherImage = "M_wk";
export var prevButton = "M_wl";
export var nextButton = "M_wm";
export var closing = "M_wn";
export var appear = "M_wp";