// extracted by mini-css-extract-plugin
export var alignLeft = "J_qj d_fp d_bG d_dv";
export var alignCenter = "J_bP d_fq d_bD d_dw";
export var alignRight = "J_qk d_fr d_bH d_dx";
export var verticalSolidComponent = "J_vd";
export var compContent = "J_t7 d_r";
export var normal = "J_vf d_Z d_cb";
export var reverse = "J_vg d_Z d_bK";
export var last = "J_t8";
export var empty = "J_vh d_r";
export var empty2 = "J_vj d_bw";
export var borderRight = "J_vk d_b8 d_c1";
export var borderLeft = "J_vl d_b9 d_c1";